import React, { Fragment, FunctionComponent, useContext, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Title } from "./Title";
import { DataContext } from "../Context/DataContext";
import { Faction, FactionName } from "../Types/DataTypes";
import { Avatar } from "@material-ui/core";
import { Spaced } from "../Common/Spaced";

const useStyles = makeStyles({
  depositContext: {
    flex: 1,
  },
  small: {
    height: 20,
    width: 20,
  },
  smallInline: {
    height: 20,
    width: 20,
    display: "inline-block",
    verticalAlign: "text-bottom",
  },
});

type Collected = Record<keyof typeof Faction, number>;

export const Totals: FunctionComponent<any> = function Totals() {
  const classes = useStyles();
  const { plays } = useContext(DataContext);

  const lastEntry = useMemo(() => (plays.length ? plays[plays.length - 1] : null), [plays]);

  const totals = useMemo(() => {
    return Object.entries(
      plays.reduce<Collected>(
        (acc, item) => item.winners.reduce((_, w) => Object.assign(acc, { [w]: (acc[w] || 0) + 1 }), acc),
        {} as Collected
      )
    ).sort(([, a], [, b]) => b - a) as [FactionName, number][];
  }, [plays]);

  return lastEntry ? (
    <>
      <Title>Totals</Title>
      <Typography component="div" variant="h4">
        {plays.length} plays
      </Typography>
      {lastEntry.winners.length ? (
        <Typography component="div" color="textSecondary" className={classes.depositContext}>
          last win:{" "}
          {lastEntry.winners
            .map((w) => <Avatar className={classes.smallInline} src={`/avatar/faction/${w}.png`} />)
            .reduce((a, b) => (
              <Fragment>
                {a} {b}
              </Fragment>
            ))}{" "}
          on turn {lastEntry.turn}
        </Typography>
      ) : null}
      {totals.slice(0, 6).map(([k, v]) => (
        <Spaced key={k} direction="horizontal">
          <Avatar className={classes.small} src={`/avatar/faction/${k}.png`} />:{" "}
          {/* {(100 * (winTotal / (lossTotal + winTotal)) || 0).toFixed()} */}
          {Math.ceil((100 / plays.length) * v)}% winrate ({v} games)
        </Spaced>
      ))}
    </>
  ) : null;
};
