import React, { FunctionComponent } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Title } from "./Title";
import { DataContext } from "../Context/DataContext";
import { Faction, Item, VictoryMethod } from "../Types/DataTypes";
import { Avatar } from "../Common/Avatar";
import { Spaced } from "../Common/Spaced";

const Fields: {
  Date: FunctionComponent<{ value: Item["date"]; data: Item }>;
  Time: FunctionComponent<{ value: Item["time"]; data: Item }>;
  Turn: FunctionComponent<{ value: Item["turn"]; data: Item }>;
  Method: FunctionComponent<{ value: Item["method"]; data: Item }>;
  Ruleset: FunctionComponent<{ value: Item["ruleset"]; data: Item }>;
  Winners: FunctionComponent<{ value: Item["winners"]; data: Item }>;
  Players: FunctionComponent<{ value: Item["players"]; data: Item }>;
} = {
  Date: ({ value }) => <>{new Date(value).toLocaleDateString()}</>,
  Time: ({ value }) => <>{value} hours</>,
  Turn: ({ value }) => <>{value}</>,
  Method: ({ value }) => <>{VictoryMethod[value]}</>,
  Ruleset: ({ value }) => <>{value}</>,
  Winners: ({ value, data }) => (
    <Spaced>
      {value.map((v) => (
        <img
          key={v}
          height="22"
          src={`/avatar/faction/${v}.png`}
          alt={Faction[v]}
          title={`${Faction[v]} (${data.players.find((p) => p.faction === v)?.name})`}
        />
      ))}
    </Spaced>
  ),
  Players: ({ value, data }) => (
    <Spaced>
      {value.map((v) => (
        <Avatar name={v.name} id={v.id} key={v.faction + v.name} mark={!!data?.winners.includes(v.faction)} />
      ))}
    </Spaced>
  ),
};

export const MyTable: FunctionComponent<{}> = function MyTable() {
  return (
    <DataContext.Consumer>
      {({ plays }) =>
        plays.length ? (
          <>
            <Title>Recent Plays</Title>
            <Table size="small">
              <TableHead>
                <TableRow>
                  {Object.keys(plays[0]).map((key) => (key === "id" ? null : <TableCell key={key}>{key}</TableCell>))}
                </TableRow>
              </TableHead>
              <TableBody>
                {plays.reverse().map((rest) => (
                  <TableRow key={rest.id} data-key={rest.id}>
                    {(Object.keys(rest) as (keyof Item)[])
                      .map((k) => {
                        switch (k) {
                          case "date": {
                            return <Fields.Date value={rest[k]} data={rest} />;
                          }
                          case "time": {
                            return <Fields.Time value={rest[k]} data={rest} />;
                          }
                          case "turn": {
                            return <Fields.Turn value={rest[k]} data={rest} />;
                          }
                          case "method": {
                            return <Fields.Method value={rest[k]} data={rest} />;
                          }
                          case "ruleset": {
                            return <Fields.Ruleset value={rest[k]} data={rest} />;
                          }
                          case "winners": {
                            return <Fields.Winners value={rest[k]} data={rest} />;
                          }
                          case "players": {
                            return <Fields.Players value={rest[k]} data={rest} />;
                          }
                          default: {
                            return null;
                          }
                        }
                      })
                      .map((element, index) => (element ? <TableCell key={index}>{element}</TableCell> : null))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </>
        ) : null
      }
    </DataContext.Consumer>
  );
};
