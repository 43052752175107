import { createContext } from "react";
import { ItemFromDB, Item, DataContextType, PlayersLookup, Player, GroupedFactions } from "../Types/DataTypes";

export const toItem = ({ ref, data }: ItemFromDB): Item => ({
  ...data,
  id: ref["@ref"].id,
  date: new Date(data.date).toJSON().split("T")[0],
});

export const reducePlayersToPlayerOptions = (acc: PlayersLookup, players: Player[], winners: Item["winners"]) => {
  players.forEach((p) => {
    if (p.name === "unknown") {
      return acc;
    }
    acc[p.name] = acc[p.name] || { id: p.id, wins: 0, losses: 0 };
    if (winners.includes(p.faction)) {
      acc[p.name].wins = acc[p.name].wins + 1;
    } else {
      acc[p.name].losses = acc[p.name].losses + 1;
    }
  });
  return acc;
};
export const reduceToPlayerOptions = (acc: PlayersLookup, item: Item) =>
  reducePlayersToPlayerOptions(acc, item.players, item.winners);

export const DataContext = createContext<DataContextType>({
  plays: [],
  factions: {} as GroupedFactions,
  players: {},
  isLoading: true,
  reload: async () => {},
  push: async () => {},
});
