import { RouteComponentProps } from "@reach/router";
import React, { FormEventHandler, FunctionComponent, useCallback, useContext } from "react";

import Fab from "@material-ui/core/Fab";
import Box from "@material-ui/core/Box";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";

import { makeStyles } from "@material-ui/core/styles";
import { useFormFields } from "./fields";
import { TheGameSection } from "./TheGameSection";
import { PlayersSection } from "./PlayersSection";
import { WinSection } from "./WinSection";
import { DataContext } from "../Context/DataContext";

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

export const Form: FunctionComponent<RouteComponentProps> = function Form({ navigate }) {
  const classes = useStyles();

  const {
    date,
    setDate,
    resetDate,
    ruleset,
    setRuleset,
    players,
    setPlayers,
    winners,
    setWinners,
    method,
    setMethod,
    turn,
    setTurn,
    time,
    setTime,
    unchoosenFactionOptions,
    winnerOptions,
    methodOptions,
    turnOptions,
    ref,
  } = useFormFields();
  const { isLoading, push: upload } = useContext(DataContext);

  const onSubmit = useCallback<FormEventHandler>((event) => {
    event.preventDefault();
  }, []);
  const onUpload = useCallback<FormEventHandler>(
    async (event) => {
      event.preventDefault();
      await upload(ref.current);

      if (navigate) {
        navigate("/");
      }
    },
    [upload, ref, navigate]
  );

  return (
    <>
      <form onSubmit={onSubmit}>
        <Box m={-3}>
          <Box m={3}>
            <TheGameSection {...{ date, setDate, resetDate, ruleset, setRuleset }} />
          </Box>
          <Box m={3}>
            <PlayersSection {...{ players, unchoosenFactionOptions, setPlayers }} />
          </Box>
          <Box m={3}>
            <WinSection
              {...{
                winners,
                winnerOptions,
                setWinners,
                method,
                methodOptions,
                setMethod,
                setTurn,
                turn,
                turnOptions,
                time,
                setTime,
              }}
            />
          </Box>
          <Box m={3}>
            <p>
              Find someone's Steam-ID here: <a href="https://steamidfinder.com/">https://steamidfinder.com/</a>
            </p>
          </Box>
        </Box>
        <Fab
          variant="extended"
          className={classes.fab}
          color="primary"
          aria-label="add"
          onClick={onUpload}
          {...(isLoading ? { disabled: true } : {})}
        >
          <CloudUploadIcon />
          <Box ml={1}>Submit</Box>
        </Fab>
      </form>
    </>
  );
};
