import { Box, Grid, makeStyles } from "@material-ui/core";
import React, { ComponentProps, FunctionComponent, useContext, useMemo } from "react";
import { Cell, ContentRenderer, Pie, PieChart, PieLabelRenderProps, ResponsiveContainer, Tooltip } from "recharts";
import { Spaced } from "../Common/Spaced";
import { DataContext } from "../Context/DataContext";
import { Turn, VictoryMethod, VictoryMethodName } from "../Types/DataTypes";
import { keys, entries } from "../utils/iteration";
import { Title } from "./Title";

const useStyles = makeStyles({
  container: {
    position: "relative",
  },
  content: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    overflow: "hidden",
  },
});

const RADIAN = Math.PI / 180;

const COLORS = [
  "#003f5c",
  "#4d456c",
  "#7c4975",
  "#a64c7e",
  "#cd4f86",
  "#dd6c6b",
  "#e6894d",
  "#efa433",
  "#f7bd19",
  "#ffd600",
];
const TURNCOLORS: Record<string, string> = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].reduce(
  (acc, item, index) => Object.assign(acc, { [item]: COLORS[index % COLORS.length] }),
  {}
);
const VICTORYCOLORS: Record<string, string> = keys(VictoryMethod).reduce(
  (acc, item, index) => Object.assign(acc, { [item]: COLORS[index % COLORS.length] }),
  {}
);
const RULESETCOLORS: Record<string, string> = {
  "GF9 basic": "#999",
  "GF9 advanced": "#666",
  Dreamrules: "#333",
};

const renderCustomizedLabel: ContentRenderer<PieLabelRenderProps> = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}) => {
  //@ts-ignore
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  //@ts-ignore
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  //@ts-ignore
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return cx && percent && percent > 0.03 ? (
    <text
      fontSize="10px"
      x={x}
      y={y}
      fill="white"
      textAnchor="middle"
      dominantBaseline="central"
      style={{ pointerEvents: "none" }}
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  ) : null;
};
export const TurnsDistribution: FunctionComponent<{}> = () => {
  const { plays } = useContext(DataContext);
  const zero: Record<Turn, number> = useMemo(
    () => ({ 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0, 10: 0 }),
    []
  );

  const sortingRulesetMethods: string[] = useMemo(() => ["GF9 basic", "GF9 advanced", "Dreamrules"], []);
  const { all, groupedByRuleset } = useMemo(() => {
    const allResults = keys(zero).reduce<Record<string, { name: string; value: number; key: string }>>(
      (acc, key) => Object.assign(acc, { [key]: { name: "Turn " + key, value: 0, key } }),
      {}
    );
    const groupedResults: Record<
      string,
      Record<string, { name: string; value: number; key: string }>
    > = sortingRulesetMethods.reduce(
      (acc, item) => Object.assign(acc, { [item]: JSON.parse(JSON.stringify(allResults)) }),
      {}
    );

    plays.forEach((play) => {
      const t = play.turn.toString();
      const r = play.ruleset;

      if (allResults[t]) {
        allResults[t].value = allResults[t].value + 1;
      }
      try{

        if (groupedResults[r][t]) {
          groupedResults[r][t].value = groupedResults[r][t].value + 1;
        }
      } catch(e){
        console.log(e)
      }
    });

    const results: { all: DataPoint[]; groupedByRuleset: [string, DataPoint[]][] } = {
      all: Object.values(allResults),
      groupedByRuleset: entries(groupedResults).map(([key, value]) => [key, Object.values(value)]),
    };

    return results;
  }, [plays, zero, sortingRulesetMethods]);

  return (
    <>
      <Title>Victory turn distribution</Title>
      <DistributionChart all={all} groupedByRuleset={groupedByRuleset} colors={TURNCOLORS} id="turns" />
    </>
  );
};

export const VictoryDistribution: FunctionComponent<{}> = () => {
  const { plays } = useContext(DataContext);
  const zero = useMemo(
    () =>
      keys(VICTORYCOLORS).reduce(
        (acc, item) => Object.assign(acc, { [item]: 0 }),
        {} as Record<VictoryMethodName, number>
      ),
    []
  );

  const sortingRulesetMethods: string[] = useMemo(() => ["GF9 basic", "GF9 advanced", "Dreamrules"], []);
  const { all, groupedByRuleset } = useMemo(() => {
    const allResults = keys(zero).reduce<Record<string, { name: string; value: number; key: string }>>(
      (acc, key) => Object.assign(acc, { [key]: { name: VictoryMethod[key], value: 0, key } }),
      {}
    );
    const groupedResults: Record<
      string,
      Record<string, { name: string; value: number; key: string }>
    > = sortingRulesetMethods.reduce(
      (acc, item) => Object.assign(acc, { [item]: JSON.parse(JSON.stringify(allResults)) }),
      {}
    );

    plays.forEach((play) => {
      try{

        allResults[play.method.toString()].value = allResults[play.method].value + 1;
        groupedResults[play.ruleset][play.method.toString()].value =
        groupedResults[play.ruleset][play.method.toString()].value + 1;
      } catch(e) {
        console.log(e)
      }
    });

    const results: { all: DataPoint[]; groupedByRuleset: [string, DataPoint[]][] } = {
      all: Object.values(allResults),
      groupedByRuleset: entries(groupedResults).map(([key, value]) => [key, Object.values(value)]),
    };

    return results;
  }, [plays, zero, sortingRulesetMethods]);

  return (
    <>
      <Title>Victory method distribution</Title>
      <DistributionChart all={all} groupedByRuleset={groupedByRuleset} colors={VICTORYCOLORS} id="methods" />
    </>
  );
};

interface DataPoint {
  name: string;
  value: number;
  key: string;
  ruleset?: string;
}

type GroupedByRuleset = [string, DataPoint[]][];

interface DistributionChartProps {
  id: string;
  groupedByRuleset: GroupedByRuleset;
  all: DataPoint[];
  colors: Record<string, string>;
}

const MyLegend: FunctionComponent<DistributionChartProps> = ({ all, colors }) => {
  return all.length ? (
    <Grid container>
      <Grid item xs={6} component={Box} paddingTop="10px">
        {entries(RULESETCOLORS).map(([key, color]) => (
          <Spaced key={key} justifyContent="flex-start">
            <Box bgcolor={color} width={10} height={10}></Box>
            <Box component="span" textAlign="left">
              {key}
            </Box>
          </Spaced>
        ))}
      </Grid>
      <Grid item xs={6}>
        {all.map(({ name, key }) => (
          <Spaced key={key} justifyContent="flex-end">
            <Box component="span" textAlign="right">
              {name}
            </Box>
            <Box bgcolor={colors[key]} width={10} height={10}></Box>
          </Spaced>
        ))}
      </Grid>
    </Grid>
  ) : null;
};

const Ratio: FunctionComponent<{ ratio: number } & ComponentProps<typeof Box>> = ({ ratio, children, ...props }) => {
  const classes = useStyles();

  return (
    <Box className={classes.container} paddingTop={100 * ratio + "%"} {...props}>
      <div className={classes.content}>{children}</div>
    </Box>
  );
};

const DistributionChart: FunctionComponent<DistributionChartProps> = ({ groupedByRuleset, colors, all, id }) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Ratio ratio={0.5} paddingBottom="20px">
          <ResponsiveContainer width={"100%"} aspect={1}>
            <PieChart margin={{ top: 2, left: 2, right: 2, bottom: 2 }}>
              <Tooltip />
              {groupedByRuleset.map(([key, data], index) => {
                const d = [{ value: data.reduce((acc, item) => acc + item.value, 0), key: 1, name: "total" }];
                return (
                  <Pie
                    key={"background" + index}
                    dataKey="value"
                    nameKey="name"
                    startAngle={220}
                    endAngle={0}
                    data={d}
                    cx="50%"
                    cy="50%"
                    innerRadius={100 - index * 22 - 17 + "%"}
                    outerRadius={100 - index * 22 + "%"}
                    label={false}
                  >
                    {d.map((entry, index) => {
                      return <Cell stroke={RULESETCOLORS[key]} strokeWidth="3" key={index} fill={RULESETCOLORS[key]} />;
                    })}
                  </Pie>
                );
              })}
              {groupedByRuleset.map(([key, data], index) => {
                return (
                  <Pie
                    key={"data" + key}
                    dataKey="value"
                    nameKey="name"
                    startAngle={180}
                    endAngle={0}
                    data={data}
                    cx="50%"
                    cy="50%"
                    innerRadius={100 - index * 22 - 17 + "%"}
                    outerRadius={100 - index * 22 + "%"}
                    labelLine={false}
                    minAngle={3}
                    label={renderCustomizedLabel}
                  >
                    {data.map((entry, index) => {
                      return <Cell stroke="transparent" strokeWidth="0" key={index} fill={colors[entry.key]} />;
                    })}
                  </Pie>
                );
              })}
            </PieChart>
          </ResponsiveContainer>
        </Ratio>
      </Grid>
      <Grid item xs={12}>
        <MyLegend groupedByRuleset={groupedByRuleset} all={all} colors={colors} id={id} />
      </Grid>
    </Grid>
  );
};
