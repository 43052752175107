import React, { EventHandler, FunctionComponent, MouseEvent, useCallback, useMemo } from "react";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader/CardHeader";
import Typography from "@material-ui/core/Typography";
import TableHead from "@material-ui/core/TableHead/TableHead";
import TableRow from "@material-ui/core/TableRow/TableRow";
import Table from "@material-ui/core/Table";
import Avatar from "@material-ui/core/Avatar";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import { navigate, RouteComponentProps } from "@reach/router";
import { Group } from "../Types/DataTypes";

export const Overview: FunctionComponent<{
  list: Group[];
  total: number;
  navigate: RouteComponentProps["navigate"];
}> = ({ list, total }) => {
  const onDetails = useCallback<EventHandler<MouseEvent<HTMLButtonElement>>>((e) => {
    e.preventDefault();

    const id = e.currentTarget?.dataset?.id;
    if (id) {
      navigate("/factions/" + id.toLowerCase());
    }
  }, []);
  const sortedByWinrate = useMemo(
    () =>
      list
        .slice()
        .sort(
          (a, b) =>
            (100 * (b.winTotal / b.lossTotal + b.winTotal) || 0) - (100 * (a.winTotal / a.lossTotal + a.winTotal) || 0)
        ),
    [list]
  );

  return (
    <Grid container spacing={3}>
      {sortedByWinrate.map(({ id, name, winTotal, lossTotal }) => (
        <Grid item xs={12} sm={6} md={4} key={id}>
          <Card>
            <CardHeader avatar={<Avatar alt={name} src={`/avatar/faction/${id}.png`} />} title={name} />
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Popularity</TableCell>
                  <TableCell>Win rate</TableCell>
                  <TableCell>Total plays</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Typography variant="body2" color="textSecondary" component="p">
                      {(100 * ((1 / total) * (winTotal + lossTotal)) || 0).toFixed()}%
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" color="textSecondary" component="p">
                      {(100 * (winTotal / (lossTotal + winTotal)) || 0).toFixed()}% (global:{" "}
                      {(100 * (winTotal / total) || 0).toFixed()}%)
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" color="textSecondary" component="p">
                      {(winTotal + lossTotal || 0).toFixed()}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <CardActions>
              <Button size="small" color="primary" onClick={onDetails} data-id={id}>
                Learn More
              </Button>
            </CardActions>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};
