import React, { FunctionComponent } from "react";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DashboardIcon from "@material-ui/icons/Dashboard";
import BubbleChartIcon from "@material-ui/icons/BubbleChart";
import PeopleIcon from "@material-ui/icons/People";
import AddIcon from "@material-ui/icons/Add";
import ForumIcon from "@material-ui/icons/Forum";

import { Link } from "@reach/router";

export const DefaultDrawer: FunctionComponent<{}> = () => {
  return (
    <>
      <Divider />
      <List>
        <ListItem button component={Link} to="/">
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem>
        <ListItem button component={Link} to="/factions">
          <ListItemIcon>
            <BubbleChartIcon />
          </ListItemIcon>
          <ListItemText primary="Factions" />
        </ListItem>
        <ListItem button component={Link} to="/players">
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText primary="Players" />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem button component={Link} to="/new">
          <ListItemIcon>
            <AddIcon />
          </ListItemIcon>
          <ListItemText primary="Add" />
        </ListItem>
      </List>
      <Divider />
      <div style={{ flex: 1 }}></div>
      <List>
        <ListItem
          button
          component={"a"}
          href="https://discord.gg/Z6qz86P"
          target="_blank"
        >
          <ListItemIcon>
            <ForumIcon />
          </ListItemIcon>
          <ListItemText primary="Discord" />
        </ListItem>
      </List>
    </>
  );
};
