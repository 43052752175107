import { AppBar, Box, Container, CssBaseline, Drawer } from "@material-ui/core";
import clsx from "clsx";
import React, { FunctionComponent, ReactElement } from "react";
import { useStyles } from "../utils/useStyles";
import { DefaultDrawer } from "./DefaultDrawer";
import { DefaultAppBar } from "./DefaultAppBar";
import { DefaultFooter } from "./DefaultFooter";
import { DrawerContext } from "../Context/DrawerContext";

export const AppLayout: FunctionComponent<{
  bar?: ReactElement;
  drawer?: ReactElement;
  main: ReactElement;
  footer?: ReactElement;
}> = function AppLayout({
  bar = <DefaultAppBar />,
  drawer = <DefaultDrawer />,
  main,
  footer = <DefaultFooter />,
}) {
  const classes = useStyles();

  return (
    <DrawerContext.Consumer>
      {([isOpen]) => (
        <div className={classes.root}>
          <CssBaseline />
          <>
            <AppBar
              position="absolute"
              className={clsx(classes.appBar, isOpen && classes.appBarShift)}
            >
              {bar}
            </AppBar>
            <Drawer
              variant="permanent"
              classes={{
                paper: clsx(
                  classes.drawerPaper,
                  !isOpen && classes.drawerPaperClose
                ),
              }}
              open={!!isOpen}
            >
              {drawer}
            </Drawer>
          </>
          <main
            className={clsx(classes.content, isOpen && classes.contentShift)}
          >
            <div className={classes.appBarSpacer} />
            <Container maxWidth="lg" className={classes.container}>
              {main}
              <Box pt={4}>{footer}</Box>
            </Container>
          </main>
        </div>
      )}
    </DrawerContext.Consumer>
  );
};
