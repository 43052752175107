import React, { FunctionComponent } from "react";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import SyncIcon from "@material-ui/icons/Sync";
import { useStyles } from "../utils/useStyles";
import { DrawerContext } from "../Context/DrawerContext";
import { DataContext } from "../Context/DataContext";

export const DefaultAppBar: FunctionComponent<{}> = () => {
  const classes = useStyles();

  return (
    <Toolbar className={classes.toolbar}>
      <DrawerContext.Consumer>
        {([, toggle]) => (
          <>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
          </>
        )}
      </DrawerContext.Consumer>
      <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
        Dune Dashboard
      </Typography>
      <DataContext.Consumer>
        {({ isLoading, reload }) => (
          <IconButton onClick={reload} {...(isLoading ? { disabled: true } : {})}>
            <SyncIcon />
          </IconButton>
        )}
      </DataContext.Consumer>
    </Toolbar>
  );
};
