import React, { FunctionComponent } from "react";
import { Item } from "../Types/DataTypes";

type Player = Item["players"][0];

export const getPlayerUrl = (name: Player["name"], id: Player["id"]) =>
  id ? `/avatar/steam/${id}.png` : `/avatar/generate/${name}`;

export const Avatar: FunctionComponent<{
  name: Player["name"];
  id: Player["id"];
  mark?: Boolean;
  size?: number;
}> = ({ name, id, mark, size = 22 }) => (
  <div
    style={{
      display: "block",
      overflow: "hidden",
      width: size,
      height: size,
      verticalAlign: "text-bottom",
      borderRadius: size / 2,
      boxShadow: mark ? "0 0 0 1px #fff, 0 0 0 3px deepskyblue" : "none",
    }}
  >
    <img height={size} src={getPlayerUrl(name, id)} alt={name} title={name} />
  </div>
);
