import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
import { Router } from "@reach/router";
import { Dashboard } from "../DashBoard";
import { Players } from "../Players";
import { Factions } from "../Factions";
import { Form } from "../Form";
import { DrawerContext, DrawerContextType } from "../Context/DrawerContext";
import { DataContext, reduceToPlayerOptions, toItem } from "../Context/DataContext";
import { AppLayout } from "../App/AppLayout";
import { PlayersLookup, DataContextType, GroupedFactions } from "../Types/DataTypes";

import * as api from "../utils/api";
import { factionGroupsBase, groupByFaction } from "../Factions/utils";

function useDrawerState() {
  const [state, setState] = useState(false);
  const toggle = useCallback(() => setState((s) => !s), [setState]);
  const combo = useMemo<DrawerContextType>(() => [state, toggle], [state, toggle]);
  return combo;
}

function useDataState(): DataContextType {
  const [{ plays, isLoading }, setState] = useState({
    plays: [],
    isLoading: true,
  });

  const push = useCallback(
    async (entry) => {
      setState((s) => ({ ...s, isLoading: true }));
      await api.playsAdd(entry);
      setState((s) => ({
        ...s,
        plays: s.plays.concat(entry),
        isLoading: false,
      }));
    },
    [setState]
  );
  const reload = useCallback(async () => {
    setState((s) => ({ ...s, isLoading: true }));
    const plays = await api.playsAll();
    setState((s) => ({ ...s, plays: plays.map(toItem), isLoading: false }));
  }, [setState]);

  const players = useMemo(() => plays.reduce(reduceToPlayerOptions, {} as PlayersLookup), [plays]);

  useEffect(() => {
    reload();
  }, [reload]);

  const factions = useMemo<GroupedFactions>(() => plays.reduce<GroupedFactions>(groupByFaction, factionGroupsBase()), [
    plays,
  ]);

  return {
    plays,
    players,
    factions,
    isLoading,
    push,
    reload,
  };
}

export const Root: FunctionComponent<{}> = () => {
  const drawerState = useDrawerState();
  const dataState = useDataState();

  return (
    <DataContext.Provider value={dataState}>
      <DrawerContext.Provider value={drawerState}>
        <AppLayout
          main={
            <Router>
              <Dashboard path="/" />
              <Players path="/players" />
              <Factions path="/factions/*" />
              <Form path="/new" />
            </Router>
          }
        />
      </DrawerContext.Provider>
    </DataContext.Provider>
  );
};
