import { RouteComponentProps } from "@reach/router";
import { useCallback, useMemo } from "react";
import { Faction, FactionName, GroupedFactions, Item } from "../Types/DataTypes";
import { ChangeHandler } from "./types";

export const factionIsBasegame = (id: FactionName) => {
  switch (id) {
    case "ATREIDES":
    case "BG":
    case "EMPEROR":
    case "FREMEN":
    case "GUILD":
    case "HARKONNEN": {
      return true;
    }
    default: {
      return false;
    }
  }
};
export const factionIsOfficial = (id: FactionName) => {
  if (factionIsBasegame(id)) {
    return true;
  }
  switch (id) {
    case "IX":
    case "CHOAM":
    case "RICHESE":
    case "TLEILAXU": {
      return true;
    }
    default: {
      return false;
    }
  }
};

export const groupByFaction = (acc: GroupedFactions, play: Item) => {
  const factions = play.players.map((p) => p.faction);

  factions.forEach((f) => {
    const win = play.winners.includes(f);
    
    if(!acc[f]) {
      return;
    }

    if (win) {
      acc[f].winTotal += 1;
      factions.forEach((ff) => {
        if (ff !== f && !play.winners.includes(ff)) {
          acc[f].winVS[ff] += 1;
        }
      });
    } else {
      acc[f].lossTotal += 1;
      factions.forEach((ff) => {
        if (ff !== f) {
          acc[f].lossVS[ff] += 1;
        }
      });
    }
  });

  return acc;
};

export const factionGroupsBase = () =>
  Object.entries(Faction).reduce(
    (acc, [id, name]) =>
      Object.assign(acc, {
        [id]: {
          id,
          name,
          winTotal: 0,
          lossTotal: 0,
          winVS: Object.keys(Faction).reduce((acc, key) => Object.assign(acc, { [key]: 0 }), {}),
          lossVS: Object.keys(Faction).reduce((acc, key) => Object.assign(acc, { [key]: 0 }), {}),
        },
      }),
    {} as GroupedFactions
  );

const panels: string[] = ["overview", ...Object.keys(Faction).map((k) => k.toLowerCase())];

export function useTabRoute(
  location: RouteComponentProps["location"],
  uri: string,
  navigate: RouteComponentProps["navigate"]
): {
  value: number;
  handleChange: ((event: React.ChangeEvent<{}>, value: any) => void) | undefined;
} {
  const { pathname } = location || {};
  const value = useMemo(() => {
    const [, subroute = "overview"] = pathname?.replace(uri, "").split("/") || [];
    return panels.indexOf(subroute);
  }, [pathname, uri]);

  const handleChange: ChangeHandler = useCallback(
    (event, v) => {
      if (navigate && panels[v]) {
        navigate(panels[v] === "overview" ? "." : panels[v]);
      }
    },
    [navigate]
  );
  return { value, handleChange };
}
