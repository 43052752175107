import React, { FunctionComponent, useMemo, useState } from "react";
import { Avatar, Box, Grid, Paper } from "@material-ui/core";
import { Spaced } from "../Common/Spaced";
import Typography from "@material-ui/core/Typography";
import { PolarAngleAxis, PolarGrid, PolarRadiusAxis, Radar, RadarChart, Tooltip } from "recharts";
import { Faction, FactionName, Group } from "../Types/DataTypes";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { factionIsOfficial, factionIsBasegame } from "./utils";

export const Detail: FunctionComponent<Group> = ({ id, name, winVS, lossVS }) => {
  const [showHomebrew, setShowHomebrew] = useState(false);
  const [showExpansionRaw, setShowExpansion] = useState(false);
  const showExpansion = showExpansionRaw || showHomebrew;

  const filteredData = useMemo(() => {
    const data = Object.keys(winVS)
      .filter((key) => key !== id)
      .map((k) => {
        const key = k as FactionName;
        return {
          key,
          subject: Faction[key],
          wins: winVS[key],
          losses: lossVS[key],
        };
      });

    return showHomebrew
      ? data
      : data.filter(({ key }) => (showExpansion ? factionIsOfficial(key) : factionIsBasegame(key)));
  }, [showHomebrew, showExpansion, id, lossVS, winVS]);

  return (
    <Paper>
      <Box padding={3} display="flex" justifyContent="center" alignItems="center">
        <Spaced>
          <Avatar src={`/avatar/faction/${id}.png`} />
          <div>{name}</div>
        </Spaced>
      </Box>
      <hr />
      <Box padding={3}>
        <Grid container>
          <Grid item md={6}>
            <Typography variant="body2" color="textSecondary" align="center">
              WINS
            </Typography>

            <RadarChart cx={300} cy={250} outerRadius={150} width={500} height={500} data={filteredData}>
              <Tooltip />
              <PolarGrid />
              <PolarAngleAxis dataKey="subject" />
              <PolarRadiusAxis />

              <Radar name="Wins" dataKey="wins" stroke="green" fill="green" fillOpacity={0.6} />
            </RadarChart>
          </Grid>
          <Grid item md={6}>
            <Typography variant="body2" color="textSecondary" align="center">
              LOSSES
            </Typography>

            <RadarChart cx={300} cy={250} outerRadius={150} width={500} height={500} data={filteredData}>
              <Tooltip />
              <PolarGrid />
              <PolarAngleAxis dataKey="subject" />
              <PolarRadiusAxis />
              <Radar name="Losses" dataKey="losses" stroke="red" fill="red" fillOpacity={0.6} />
            </RadarChart>
          </Grid>
        </Grid>
      </Box>
      <Box padding={3}>
        <FormControlLabel
          control={
            <Switch
              checked={showExpansion}
              onChange={(event) => setShowExpansion(event.target.checked)}
              name="expansions"
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
          }
          label="Display expansion factions"
        />
        <FormControlLabel
          control={
            <Switch
              checked={showHomebrew}
              onChange={(event) => setShowHomebrew(event.target.checked)}
              name="homebrew"
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
          }
          label="Display homebrew factions"
        />
      </Box>
    </Paper>
  );
};
