export const keys = Object.keys as <T>(o: T) => Extract<keyof T, string>[];

type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

export function entries<T>(obj: T): Entries<T> {
  return Object.entries(obj) as any;
}

export const values = Object.values as <T>(o: T) => T[keyof T][];
