import React, { FunctionComponent } from "react";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { PlayerFormSection } from "./PlayerFormSection";
import { FormFields } from "./fields";

export const PlayersSection: FunctionComponent<
  FormFields<"players" | "setPlayers" | "unchoosenFactionOptions">
> = ({ players, unchoosenFactionOptions, setPlayers }) => (
  <Card>
    <CardContent>
      <Typography color="textSecondary" gutterBottom>
        Players
      </Typography>
      {players.map((p, index) => (
        <PlayerFormSection
          key={index}
          index={index + 1}
          player={p}
          unchoosenFactions={unchoosenFactionOptions}
          setPlayers={setPlayers}
        />
      ))}
      <PlayerFormSection
        key={players.length}
        unchoosenFactions={unchoosenFactionOptions}
        index={players.length + 1}
        setPlayers={setPlayers}
      />
    </CardContent>
  </Card>
);
