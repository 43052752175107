/* Api methods to call /functions */

export const create = (data) => {
  return fetch("/.netlify/functions/todos-create", {
    body: JSON.stringify(data),
    method: "POST",
  }).then((response) => {
    return response.json();
  });
};

export const playsAdd = (data) =>
  fetch("/.netlify/functions/plays-add", {
    body: JSON.stringify(data),
    method: "POST",
  }).then(async (r) =>
    r.status.toString().match(/2../)
      ? r.json()
      : Promise.reject(new Error(await r.json()))
  );

export const playsAll = (data) =>
  fetch("/.netlify/functions/plays-read-all").then(async (r) =>
    r.status.toString().match(/2../)
      ? r.json()
      : Promise.reject(new Error(await r.json()))
  );

export const readAll = () => {
  return fetch("/.netlify/functions/todos-read-all").then((response) => {
    return response.json();
  });
};

export const update = (todoId, data) => {
  return fetch(`/.netlify/functions/todos-update/${todoId}`, {
    body: JSON.stringify(data),
    method: "POST",
  }).then((response) => response.json());
};

export const deleteTodo = (todoId) => {
  return fetch(`/.netlify/functions/todos-delete/${todoId}`, {
    method: "POST",
  }).then((response) => {
    return response.json();
  });
};

export const batchDeleteTodo = (todoIds) => {
  return fetch(`/.netlify/functions/todos-delete-batch`, {
    body: JSON.stringify({
      ids: todoIds,
    }),
    method: "POST",
  }).then((response) => {
    return response.json();
  });
};
