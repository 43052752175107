import React, { FunctionComponent } from "react";
import { Copyright } from "../DashBoard/Copyright";

export const DefaultFooter: FunctionComponent<{}> = () => {
  return (
    <>
      <Copyright />
    </>
  );
};
