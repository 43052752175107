import React, { FunctionComponent } from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import { Chart } from "./Chart";
import { Totals } from "./Totals";
import { MyTable } from "./Table";
import { useStyles } from "../utils/useStyles";
import { RouteComponentProps } from "@reach/router";
import { TurnsDistribution, VictoryDistribution } from "./WinDistributions";
import { Hidden } from "@material-ui/core";

export const Dashboard: FunctionComponent<RouteComponentProps> = function Dashboard() {
  const classes = useStyles();

  return (
    <>
      <Grid container spacing={3}>
        <Grid item container spacing={3}>
          <Hidden xsDown>
            <Grid item container xs={false} sm={8} lg={9}>
              <Grid item component={Paper} className={classes.paper} style={{ flex: 1, width: "100%", minHeight: 300 }}>
                <Chart />
              </Grid>
            </Grid>
          </Hidden>
          <Grid item container xs={12} sm={4} lg={3}>
            <Grid item component={Paper} className={classes.paper} style={{ flex: 1 }}>
              <Totals />
            </Grid>
          </Grid>
        </Grid>

        <Grid item container spacing={3}>
          <Grid item container xs={12} md={6}>
            <Grid item component={Paper} className={classes.paper} style={{ flex: 1 }}>
              <TurnsDistribution />
            </Grid>
          </Grid>
          <Grid item container xs={12} md={6}>
            <Grid item component={Paper} className={classes.paper} style={{ flex: 1 }}>
              <VictoryDistribution />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <MyTable />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};
