import React, { createContext } from "react";

export type DrawerContextType = [
  Boolean,
  (...args: any[]) => ReturnType<React.Dispatch<React.SetStateAction<boolean>>>
];

export const DrawerContext = createContext<DrawerContextType>([
  false,
  () => true,
]);
