import React, { FunctionComponent } from "react";

export const TabPanel: FunctionComponent<{ value: number; index: number }> = (
  props
) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index ? children : null}
    </div>
  );
};
