import React, { FunctionComponent, useContext, useMemo } from "react";

import { RouteComponentProps } from "@reach/router";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";

import { DataContext } from "../Context/DataContext";
import { Overview } from "./Overview";
import { Spaced } from "../Common/Spaced";

import { Detail } from "./Detail";
import { TabPanel } from "./TabPanel";
import { useTabRoute } from "./utils";

const useStyles = makeStyles({
  small: {
    width: 20,
    height: 20,
  },
});

export const Factions: FunctionComponent<RouteComponentProps> = function Factions({ navigate, location, uri = "" }) {
  const { factions, plays } = useContext(DataContext);
  const list = useMemo(() => Object.values(factions), [factions]);
  const total = plays.length;

  const { value, handleChange } = useTabRoute(location, uri, navigate);
  const classes = useStyles();

  return (
    <>
      <Box marginBottom="10px">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label="Overview" />
          {list.map(({ id, name }) => (
            <Tab
              key={id}
              label={
                <Spaced direction="horizontal">
                  <Avatar src={`/avatar/faction/${id}.png`} className={classes.small} />
                  <span>{name}</span>
                </Spaced>
              }
            />
          ))}
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Overview list={list} total={total} navigate={navigate} />
      </TabPanel>
      {list.map((group, index) => (
        <TabPanel key={group.id} value={value} index={index + 1}>
          <Detail {...group} />
        </TabPanel>
      ))}
    </>
  );
};
