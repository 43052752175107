import React, { FunctionComponent } from "react";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

export const Copyright: FunctionComponent<{}> = function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://dune-stats.netlify.app/">
        Dune Stats
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
};
