interface DBRef {
  "@ref": {
    id: string;
    collection?: DBRef;
  };
}

export interface ItemFromDB {
  ref: DBRef;
  ts: number;
  data: DataFromDB;
}

export enum Faction {
  HARKONNEN = "Harkonnen",
  FREMEN = "Fremen",
  EMPEROR = "Emperor",
  BG = "Bene Gesserit",
  ATREIDES = "Atreides",
  GUILD = "Spacing Guild",
  IX = "Ix",
  TLEILAXU = "Bene Tleilaxu",
  LANDSRAAD = "Landsraad",
  MERCENARIES = "Mercenaries",
  GINAZ = "Ginaz",
  IDUALI = "Iduali",
  CHOAM = "CHOAM",
  RICHESE = "Richese",
  MORITANI = "Moritani",
  QIZARATE = "Qizarate",
}

export enum VictoryMethod {
  STRONGHOLDS = "Strongholds",
  PREDICTION = "Prediction",
  FREMEN_DEFAULT = "Fremen special victory",
  GUILD_DEFAULT = "Guild special victory",
  FORFEIT = "Forfeit",
  TIMEOUT = "Timeout",
}

export type Turn = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;

// https://api.steampowered.com/ISteamUser/GetPlayerSummaries/v0002/?key=B4DC17512CC6EABD08105CDE53983A41&steamids=76561198057558453
// https://steamprofile.com/sig/76561198057558453/
export interface Player {
  name: string;
  id?: string | undefined;
  faction: keyof typeof Faction;
}

interface DataFromDB {
  date: string;
  time: number;
  turn: Turn;
  ruleset: string;
  players: Player[];
  winners: (keyof typeof Faction)[];
  method: keyof typeof VictoryMethod;
}

export type FactionName = keyof typeof Faction;
export type VictoryMethodName = keyof typeof VictoryMethod;

export type Item = DataFromDB & { id: string };

export interface DataContextType {
  plays: Item[];
  players: PlayersLookup;
  factions: GroupedFactions;
  isLoading: boolean;
  reload: (...args: any[]) => void;
  push: (...args: any[]) => ReturnType<React.Dispatch<React.SetStateAction<boolean>>>;
}

export interface PlayerData {
  id: Player["id"];
  wins: number;
  losses: number;
}

export type PlayersLookup = Record<Player["name"], PlayerData>;

export type GroupedFactions = Record<
  FactionName,
  {
    id: FactionName;
    name: Faction;
    winTotal: number;
    lossTotal: number;
    winVS: Record<FactionName, number>;
    lossVS: Record<FactionName, number>;
  }
>;

export type Group = GroupedFactions[FactionName];
