import { ChangeEvent, Dispatch, SyntheticEvent, useCallback, useMemo, useRef, useState } from "react";
import { Faction, FactionName, Player, Turn, VictoryMethod, VictoryMethodName } from "../Types/DataTypes";
import { Handler, SetPlayersFn } from "./types";

export function useFormFields() {
  const [players, setPlayersRaw] = useState<Player[]>([]);
  const [winners, setWinnersRaw] = useState<FactionName[]>([]);
  const [method, setMethodRaw] = useState<VictoryMethodName>("STRONGHOLDS");
  const [date, setDateRaw] = useState(new Date(Date.now()));
  const [time, setTimeRaw] = useState(1.5);
  const [turn, setTurnRaw] = useState<Turn>(1);
  const [ruleset, setRulesetRaw] = useState<string>("GF9 advanced");

  const factionsOptions = useMemo<FactionName[]>(() => Object.keys(Faction) as FactionName[], []);
  const unchoosenFactionOptions = useMemo<FactionName[]>(
    () => factionsOptions.filter((f) => !players.find((p) => p.faction === f)),
    [players, factionsOptions]
  );
  const methodOptions = useMemo(() => Object.keys(VictoryMethod) as VictoryMethodName[], []);
  const winnerOptions = useMemo(() => players.map((p) => p.faction).filter(Boolean), [players]);
  const turnOptions: Turn[] = useMemo(() => [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], []);

  const setDate = useCallback<Dispatch<ChangeEvent<HTMLInputElement>>>(
    ({ target: { value } }) => setDateRaw(new Date(value)),
    [setDateRaw]
  );
  const setRuleset = useCallback<Handler<string | null>>((event, value) => setRulesetRaw(value || "GF9 advanced"), [
    setRulesetRaw,
  ]);
  const setTime = useCallback<Dispatch<ChangeEvent<HTMLInputElement>>>(
    ({ target: { value } }) => {
      let result = 0;
      const [hours, minutes] = value ? value.split(":") : ["00", "00"];
      result = result + parseInt(hours, 10);
      result = result + (minutes === "30" ? 0.5 : 0);
      setTimeRaw(result);
    },
    [setTimeRaw]
  );
  const resetDate = useCallback<Dispatch<SyntheticEvent>>(
    (event) => {
      event.preventDefault();
      return setDateRaw(new Date(Date.now()));
    },
    [setDateRaw]
  );
  const setPlayers = useCallback<SetPlayersFn>(
    (input) => {
      const data = typeof input === "function" ? input(ref.current.players) : input;
      setPlayersRaw(data.filter((p) => p.faction || p.name));
    },
    [setPlayersRaw]
  );
  const setWinners = useCallback<Handler<FactionName[] | null>>((event, input) => setWinnersRaw(input || []), [
    setWinnersRaw,
  ]);
  const setTurn = useCallback<Handler<Turn | null>>((event, input) => setTurnRaw(input || 1), [setTurnRaw]);
  const setMethod = useCallback<Handler<VictoryMethodName | null>>(
    (event, input) => setMethodRaw(input || "STRONGHOLDS"),
    [setMethodRaw]
  );

  const state = {
    date,
    time,
    turn,
    ruleset,
    players,
    winners,
    method,
  };

  const ref = useRef(state);
  ref.current = state;

  return {
    setDate,
    resetDate,
    setRuleset,
    unchoosenFactionOptions,
    setPlayers,
    winnerOptions,
    setWinners,
    setMethod,
    methodOptions,
    setTurn,
    turnOptions,
    setTime,
    players,
    winners,
    method,
    date,
    time,
    turn,
    ruleset,
    ref,
  };
}

export type FormFields<T extends keyof ReturnType<typeof useFormFields>> = Pick<ReturnType<typeof useFormFields>, T>;
