import React, { FunctionComponent } from "react";
import { RouteComponentProps } from "@reach/router";

import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader/CardHeader";

import { DataContext } from "../Context/DataContext";
import { PlayerData } from "../Types/DataTypes";
import { Avatar } from "../Common/Avatar";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import Typography from "@material-ui/core/Typography";

const getFactor = (a: number, b: number) => 1 - 1 / (((a + b) / 5) * ((a + b) / 5) + 1);

const getScore = (p: PlayerData) => 2.5 + p.wins - (p.losses / p.wins) * getFactor(p.losses, p.wins);

const sortByScore = ([, p1]: [string, PlayerData], [, p2]: [string, PlayerData]): number => getScore(p2) - getScore(p1);

export const Players: FunctionComponent<RouteComponentProps> = function Players() {
  return (
    <>
      <Grid container spacing={3}>
        <DataContext.Consumer>
          {({ players }) => (
            <>
              {Object.entries(players)
                .sort(sortByScore)
                .map(([name, playerData]) => (
                  <Grid item xs={12} sm={6} md={4} key={name}>
                    <Card>
                      <CardHeader
                        avatar={<Avatar key={name} size={40} name={name} id={playerData.id} />}
                        title={name}
                      />
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>Score</TableCell>
                            <TableCell>Wins</TableCell>
                            <TableCell>Losses</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell>
                              <Typography variant="body2" color="textSecondary" component="p">
                                {getScore(playerData) <= 0.1 ? "no score" : getScore(playerData).toFixed(1)}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="body2" color="textSecondary" component="p">
                                <span>{playerData.wins} </span>
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="body2" color="textSecondary" component="p">
                                <span>{playerData.losses} </span>
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Card>
                  </Grid>
                ))}
            </>
          )}
        </DataContext.Consumer>
      </Grid>
    </>
  );
};
