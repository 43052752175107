import React, { FunctionComponent } from "react";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Faction, VictoryMethod } from "../Types/DataTypes";
import { FormFields } from "./fields";

export const printTime = (count: number) => {
  const int = Math.floor(count);
  const remainder = count - int;

  return (int < 10 ? "0" + int : int).toString() + ":" + (remainder > 0 ? "30" : "00").toString();
};

export const WinSection: FunctionComponent<
  FormFields<
    | "winners"
    | "setWinners"
    | "winnerOptions"
    | "method"
    | "setMethod"
    | "methodOptions"
    | "turn"
    | "setTurn"
    | "turnOptions"
    | "time"
    | "setTime"
  >
> = ({
  winners,
  winnerOptions,
  setWinners,
  method,
  methodOptions,
  setMethod,
  setTurn,
  turn,
  turnOptions,
  time,
  setTime,
}) => (
  <Card>
    <CardContent>
      <Typography color="textSecondary" gutterBottom>
        Who won?
      </Typography>
      <Autocomplete
        multiple
        autoSelect
        id="winners"
        options={winnerOptions}
        getOptionLabel={(f) => Faction[f]}
        value={winners || null}
        filterSelectedOptions
        onChange={setWinners}
        renderInput={(params) => (
          <TextField {...params} label="Winners" margin="normal" placeholder="Select winner(s)" />
        )}
      />
      <Autocomplete
        id="method"
        autoSelect
        aria-required
        value={method || "STRONGHOLDS"}
        onChange={setMethod}
        options={methodOptions}
        getOptionLabel={(v) => VictoryMethod[v]}
        renderInput={(params) => <TextField required {...params} label="Victory method" margin="normal" />}
      />
      <Grid container spacing={3} alignItems="flex-end">
        <Grid item xs={6}>
          <Autocomplete
            id="turn"
            autoSelect
            value={turn || 1}
            onChange={setTurn}
            options={turnOptions}
            getOptionLabel={(v) => v.toString()}
            renderInput={(params) => <TextField required {...params} label="Turn" margin="normal" />}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="time"
            label="Play time (number of hours, eg: 1:30)"
            type="time"
            margin="normal"
            value={printTime(time)}
            onChange={setTime}
            inputProps={{
              step: 1800,
            }}
            fullWidth
          />
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);
