import React, { FunctionComponent, Dispatch, useCallback, useContext, useMemo, useRef, ChangeEvent } from "react";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Faction, Player } from "../Types/DataTypes";
import { DataContext } from "../Context/DataContext";
import { Handler, SetPlayersFn } from "./types";
import { Avatar as PlayerAvatar } from "../Common/Avatar";

export const PlayerFormSection: FunctionComponent<{
  player?: Player;
  index: number;
  unchoosenFactions: (keyof typeof Faction)[];
  setPlayers: SetPlayersFn;
}> = ({ player, index, unchoosenFactions, setPlayers }) => {
  const ref = useRef({ player, defaultFaction: unchoosenFactions[0] });
  ref.current = { player, defaultFaction: unchoosenFactions[0] };

  const { players } = useContext(DataContext);

  const names = useMemo(() => Object.keys(players).sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase())), [
    players,
  ]);
  const onChange = useCallback<Dispatch<Player>>(
    (changedPlayer) => {
      setPlayers((players) => {
        const copy = [...players];
        copy[index - 1] = changedPlayer;

        return copy;
      });
    },
    [setPlayers, index]
  );
  const onChangeName = useCallback<Handler<Player["name"]>>(
    (event, name) =>
      onChange({
        ...{ faction: ref.current.defaultFaction },
        ...ref.current.player,
        id: players[name] ? players[name].id : undefined,
        name,
      }),
    [onChange, players]
  );
  const onChangeFaction = useCallback<Handler<Player["faction"]>>(
    (event, faction) =>
      onChange({
        name: "unknown",
        ...ref.current.player,
        faction,
      }),
    [onChange]
  );
  const onChangeId = useCallback<(event: ChangeEvent<HTMLInputElement>) => void>(
    (event) =>
      onChange({
        faction: ref.current.defaultFaction,
        name: "unknown",
        ...ref.current.player,
        id: event.target.value,
      }),
    [onChange]
  );

  const factionOptions = useMemo(() => {
    if (player?.faction) {
      const copy = unchoosenFactions.slice();
      copy.unshift(player.faction);
      return copy;
    }
    return unchoosenFactions;
  }, [player?.faction, unchoosenFactions]);

  return (
    <Grid container spacing={3} alignItems="flex-end">
      <Grid item>
        <Avatar variant="square" style={{ backgroundColor: "rgba(0,0,0,0.1)" }}>
          {player?.faction ? index : "+"}
        </Avatar>
      </Grid>
      <Grid item sm={4} xs={10}>
        <Autocomplete
          id={index + "_name"}
          options={names}
          value={player?.name}
          onChange={onChangeName as any}
          freeSolo
          autoSelect
          renderInput={(params) =>
            player?.name ? (
              <Grid container alignItems="flex-end">
                <Grid item style={{ marginRight: 10 }}>
                  <PlayerAvatar name={player.name} id={player.id} size={40} />
                </Grid>
                <Grid item xs>
                  <TextField {...params} label="Name" />
                </Grid>
              </Grid>
            ) : (
              <TextField {...params} label="Name" />
            )
          }
        />
      </Grid>
      <Grid item sm={4} xs={12}>
        <Autocomplete
          id={index + "_faction"}
          options={factionOptions}
          value={player?.faction}
          autoSelect
          onChange={onChangeFaction as any}
          getOptionLabel={(v) => Faction[v] || "_"}
          renderInput={(params) =>
            player?.faction ? (
              <Grid container alignItems="flex-end">
                <Grid item style={{ marginRight: 10 }}>
                  <Avatar src={`/avatar/faction/${player.faction}.png`} />
                </Grid>
                <Grid item xs>
                  <TextField {...params} label="Faction" />
                </Grid>
              </Grid>
            ) : (
              <TextField {...params} label="Faction" />
            )
          }
        />
      </Grid>
      <Grid item sm={3} xs={12}>
        <TextField
          disabled={player?.id !== undefined && player?.id === players[player?.name as string].id}
          fullWidth
          id={index + "_id"}
          value={player?.id || ""}
          label="Steam-ID"
          onChange={onChangeId}
        />
      </Grid>
    </Grid>
  );
};
