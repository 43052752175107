import React, { FunctionComponent } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  ResponsiveContainer,
  CartesianGrid,
  Tooltip,
  Legend,
  Brush,
  ReferenceLine,
} from "recharts";
import withWidth, { WithWidth } from "@material-ui/core/withWidth";
import { Title } from "./Title";
import { DataContext } from "../Context/DataContext";

const Raw: FunctionComponent<WithWidth> = ({ width }) => {
  return (
    <DataContext.Consumer>
      {({ plays }) => (
        <>
          <Title>Turn / Playtime</Title>
          <ResponsiveContainer width={"100%"} aspect={width === "sm" ? 2 : 3}>
            <LineChart width={500} height={200} data={plays}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Legend verticalAlign="top" wrapperStyle={{ lineHeight: "40px" }} />
              <ReferenceLine y={0} stroke="#000" />
              <Brush dataKey="date" height={10} stroke="#8884d8" />
              <Line type="monotone" dataKey="turn" stroke="#8884d8" strokeDasharray="5 5" />
              <Line type="monotone" dataKey="time" stroke="#82ca9d" strokeDasharray="3 4 5 2" />
            </LineChart>
          </ResponsiveContainer>
        </>
      )}
    </DataContext.Consumer>
  );
};

export const Chart = withWidth()(Raw);
