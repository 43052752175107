import React, { FunctionComponent } from "react";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Button } from "@material-ui/core";
import { FormFields } from "./fields";

const printDate = (date: Date) => date.toJSON().split("T")[0];

export const TheGameSection: FunctionComponent<
  FormFields<"date" | "setDate" | "resetDate" | "ruleset" | "setRuleset">
> = ({ date, setDate, resetDate, ruleset, setRuleset }) => (
  <Card>
    <CardContent>
      <Typography color="textSecondary" gutterBottom>
        The game
      </Typography>
      <Grid container spacing={3} alignItems="flex-end">
        <Grid item sm={10} xs={12}>
          <TextField
            InputLabelProps={{ shrink: true }}
            required
            id="date"
            label="Date"
            type="date"
            value={printDate(date)}
            onChange={setDate}
            fullWidth
            margin="normal"
          />
        </Grid>
        <Grid item sm={2} xs={12}>
          <Button fullWidth onClick={resetDate} variant="outlined">
            Today
          </Button>
        </Grid>
      </Grid>
      <Autocomplete
        id="ruleset"
        value={ruleset || "GF9 advanced"}
        onChange={setRuleset}
        options={["GF9 advanced", "GF9 basic", "Dreamrules"]}
        freeSolo
        renderInput={(params) => <TextField required {...params} label="Ruleset" margin="normal" />}
      />
    </CardContent>
  </Card>
);
