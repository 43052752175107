import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import React, { ComponentProps, FunctionComponent } from "react";

const useStyles = makeStyles({
  vertical: {
    display: "flex",
    alignItems: "center",
    "& * + *": {
      marginLeft: 4,
    },
  },
  horizontal: {
    display: "flex",
    alignItems: "center",
    "& * + *": {
      marginLeft: 4,
    },
  },
});

export const Spaced: FunctionComponent<
  {
    direction?: keyof ReturnType<typeof useStyles>;
  } & ComponentProps<typeof Box>
> = ({ children, direction = "horizontal", ...rest }) => {
  const classes = useStyles();

  return (
    <Box {...rest} className={classes[direction]}>
      {children}
    </Box>
  );
};
